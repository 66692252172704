var app = new Vue({
    el: '#app',
    mixins: [App.mixin],
    data() {
        return {
            form: new Form({
                locale: App.currentLocale,
                account: '',
                password: '',
                "g-recaptcha-response": null,
            }),
        }
    },
    methods: {
        submitForm(formName) {
            let that = this;
            that.base.isLoading = true;
            that.form.post(App.currentRoute.url)
                .then(function(response){
                    // console.log('response', response)
                    that.form.reset();
                })
                .catch(function(error){
                    // console.log('error', error)
                    that.form.onFail(error);
                });
        },
    },
});
$(window).on('load', function() {
    const swiper_login = new Swiper('.swiper_login', {
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 600,
        pagination: {
            el: '.page_login',
            type: 'bullets',
        },
        autoplay: {
            delay: 4000,
        },
    });
});
